<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-lead-order-submenu></q-agent-lead-order-submenu>
        <v-row class="mx-5">
            <v-col cols=12 class="text-center">
                <q-moloney-fillrate-report></q-moloney-fillrate-report>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QMoloneyFillrateReport from '@/components/lead_orders/QMoloneyFillrateReport.vue';
import QAgentLeadOrderSubmenu from '@/components/navigation/Submenus/QAgentLeadOrderSubmenu.vue';

export default {
    data() {
        return {
            //search: '',
        }
    },
    components: {
        QMoloneyFillrateReport,
        QAgentLeadOrderSubmenu,
        
    },
    methods: {

    },

}

</script>
