<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-lead-order-submenu></q-agent-lead-order-submenu>
        <v-row class="mx-5">
            <v-col cols=12 class="text-center">
                <q-lead-orders-fill-rate-data-table></q-lead-orders-fill-rate-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QLeadOrdersFillRateDataTable from '@/components/datatables/LeadOrders/QLeadOrdersFillRateDataTable.vue';
import QAgentLeadOrderSubmenu from '@/components/navigation/Submenus/QAgentLeadOrderSubmenu.vue';

export default {
    data() {
        return {
            //search: '',
        }
    },
    components: {
        QLeadOrdersFillRateDataTable,
        QAgentLeadOrderSubmenu
    },
    methods: {

    },

}

</script>
