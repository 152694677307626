<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-lead-order-submenu></q-agent-lead-order-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <h2>Allocate Leads
                    <v-btn @click="reset">
                        Reset
                    </v-btn>
                </h2>
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            </v-col>
            <v-col cols="12">
                <v-stepper v-model="tab">
                    <v-stepper-header>
                        <v-stepper-step step="1">
                            Draft Allocations
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">
                            Review / Edit
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">
                            Download
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-content step="1">
                        <p>This will pull all Zoho leads since the last allocation - or you can enter a custom date.</p>
                        <v-form ref="form" v-model="valid" lazy-validation :disabled="loading">
                            <v-row class="mx-5">
                                <v-col cols="4">
                                    <v-select v-model="lead_type" label="Lead Type" :rules="[form_rules.required]" :items="['Recruiting', 'Quility Digital Lead']"></v-select>
                                </v-col>
                                <v-col cols="4">
                                    <q-date-picker v-model="sinceDate" label="All Zoho Leads Since Date" :rules="[form_rules.required, form_rules.date]"></q-date-picker>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field v-model="sinceTime" label="Since Time" :rules="[form_rules.required, form_rules.time]"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn :disabled="!valid || loading" color="primary" @click="startDraftAllocations">
                                        Draft Allocations
                                        <v-icon right>fas fa-arrow-alt-circle-down</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row wrap>
                            <v-col cols="12">
                                <q-lead-allocation-log-data-table ref="allocation_log"></q-lead-allocation-log-data-table>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-row wrap>
                            <v-col cols="3">
                                Total Leads: {{leads.length}}
                            </v-col>
                            <v-col cols="3">
                                Allocated Leads: {{allocated_leads.length}}
                            </v-col>
                            <v-col cols="12">
                                <q-lead-allocation-review-data-table ref="leads_table" :leads="leads"></q-lead-allocation-review-data-table>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-btn large :disabled="loading" color="primary" @click="saveAllocationsAndDownload">
                                    Save & Download
                                    <v-icon right v-if="!loading">fas fa-arrow-alt-circle-down</v-icon>
                                    <v-progress-circular size="13" v-else indeterminate></v-progress-circular>
                                </v-btn>
                                <p>Note: Clicking Save & Download will automatically <b>ALL</b> log allocations for these leads, regardless of the filters applied on the list. <br> It will also mark any One-time Orders as "Complete" if their states have been filled. </p>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <img src="/assets/images/GoodJob2.gif"><br>
                                <v-btn color="primary" @click="downloadCSV">Download</v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QAgentLeadOrderSubmenu from '@/components/navigation/Submenus/QAgentLeadOrderSubmenu.vue';
import QDatePicker from '@/components/utils/QDatePicker.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QLeadAllocationLogDataTable from '@/components/datatables/LeadOrders/QLeadAllocationLogDataTable.vue'
import QLeadAllocationReviewDataTable from '@/components/datatables/LeadOrders/QLeadAllocationReviewDataTable.vue'
import moment from 'moment'

export default {
    data() {
        return {
            tab: 1,
            sinceDate: null,
            sinceTime: null,
            pullTime: null,
            loading: false,
            leads: [],
            valid: true,
            lead_type: 'Quility Digital Lead'
        }
    },
    mounted: function() {
        //load the last synced date from server. 
        this.reset();
    },
    computed: {
        allocated_leads: function() {
            var allocated = [];
            this.leads.forEach(function(l) {
                if (typeof l.AGENTID != 'undefined' && l.AGENTID != null && l.AGENTID != "") {
                    allocated.push(l)
                }
            })
            return allocated;
        },
    },
    components: {
        QAgentLeadOrderSubmenu,
        QDatePicker,
        QLeadAllocationLogDataTable,
        QLeadAllocationReviewDataTable
    },
    methods: {
        'reset': function() {
            this.tab = 1;
            var g = this;
            g.sinceDate = null;
            g.sinceTime = null;
            g.loading = true
            this.$refs.leads_table.reset()
            this.$refs.allocation_log.reset()
            QuilityAPI.getLastIAAllocationDate(this.lead_type)
                .then(function(last_run_date) {
                    g.$set(g, 'sinceDate', moment(last_run_date).format('YYYY-MM-DD'));
                    g.$set(g, 'sinceTime', moment(last_run_date).format('LT'));
                    g.loading = false
                });
        },
        'startDraftAllocations': function() {
            this.tab = 1;
            var g = this;
            g.loading = true
            QuilityAPI.draftIAAllocations(this.sinceDate + " " + this.sinceTime, this.lead_type)
                .then(function(json) {
                    if (typeof json.exception != 'undefined') {
                        g.loading = false
                        g.showError(json.message)
                        return;
                    }
                    g.$set(g, 'leads', json.data);
                    g.$set(g, 'sinceDate', moment(json.meta.run_time).format('YYYY-MM-DD'));
                    g.$set(g, 'sinceTime', moment(json.meta.run_time).format('LT'));
                    g.$set(g, 'pullTime', json.meta.pullTime);
                    g.loading = false
                    g.tab = 2
                }).catch(function(err) {
                    console.log(err)
                    g.loading = false
                    if (typeof err.exception != 'undefined') {
                        g.loading = false
                        g.showError(err.message)
                        return;
                    }
                    if (typeof err.msg != 'undefined') {
                        g.loading = false
                        g.showError(err.msg)
                        return;
                    }
                    g.showError(err)
                });
        },
        saveAllocationsAndDownload: function() {
            var g = this;
            g.loading = true
            QuilityAPI.saveIAAllocations(this.leads, this.sinceDate + " " + this.sinceTime, this.lead_type, this.pullTime)
                .then(function(json) {
                    if (typeof json.exception != 'undefined') {
                        g.loading = false
                        g.showError("There was a problem allocating leads", json.message)
                        return;
                    }
                    g.loading = false
                    g.tab = 3
                    g.document_url = json.download_url;
                    g.$nextTick(function() {
                        g.downloadCSV();
                    });
                }).catch(function(err) {
                    console.log(err)
                    g.loading = false
                    if (typeof err.exception != 'undefined') {
                        g.loading = false
                        g.showError("There was a problem allocating leads", err.message)
                        return;
                    }
                    if (typeof err.msg != 'undefined') {
                        g.loading = false
                        g.showError("There was a problem allocating leads", err.msg)
                        return;
                    }
                    g.showError(err)
                });
        },
        downloadCSV: function() {
            var g = this;
            g.loading = true
            var g = this
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            QuilityAPI.downloadAllocationCSV(this.document_url)
                .then(function(csv) {
                    g.loading = false
                    var blob = new Blob([csv], { type: "text/csv" }),
                        url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.target = "_blank"
                    a.download = g.document_url;
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
        }
    },
    watch: {
        'lead_type': function() {
            this.$nextTick(this.reset);
        }
    }
}
</script>