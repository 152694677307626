<template>
	<v-container fluid class="grey lighten-5 my-0 px-0  py-0">
		<q-agent-lead-order-submenu v-if="hasRole(['SuperAdmin', 'Staff', 'Exec'])"></q-agent-lead-order-submenu>
		<q-my-leads-submenu v-else></q-my-leads-submenu>
		<v-row class="mx-5">
			<v-col cols=12 class="text-center">
				<EconomyLeadOrdersTable :agent="user.Agent" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import EconomyLeadOrdersTable from './EconomyLeadOrdersTable.vue';
import QAgentLeadOrderSubmenu from '@/components/navigation/Submenus/QAgentLeadOrderSubmenu.vue';
import QMyLeadsSubmenu from '@/components/navigation/Submenus/QMyLeadsSubmenu.vue';

export default {
	components: {
		EconomyLeadOrdersTable,
		QAgentLeadOrderSubmenu,
		QMyLeadsSubmenu
	},
}
</script>
