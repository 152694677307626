<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-leads-submenu ></q-my-leads-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <q-baseshop-lead-order-report-table title="My Agency's Lead Orders" :agent-code="user.Agent.AgentCode"></q-baseshop-lead-order-report-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QBaseshopLeadOrderReportTable from '@/components/datatables/LeadOrders/QBaseshopLeadOrderReportTable.vue';
import QMyLeadsSubmenu from '@/components/navigation/Submenus/QMyLeadsSubmenu.vue';

export default {
    data() {
        return {
            //search: '',
        }
    },
    computed: {

    },
    components: {
        QBaseshopLeadOrderReportTable,
        QMyLeadsSubmenu
    },
    methods: {},

}

</script>
